var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row PropertyListItem",
    class: {
      'PropertyListItem--border': _vm.border
    }
  }, [_c('div', {
    class: _vm.colSize
  }, [_c('span', {
    staticClass: "font-w600 text-muted no-wrap"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2)]), _c('div', {
    staticClass: "col",
    class: {
      'text-right': _vm.alignRight
    }
  }, [_c('span', {
    class: {
      'text-uppercase': _vm.uppercase,
      'text-capitalize': _vm.capitalize
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.value))];
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }