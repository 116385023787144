<template>
  <div data-cy="asset-accounts-contracts-page">
    <TabTitle class="mb-4" icon="fa-file-contract" action-position="right">
      <template>Contracts</template>
      <template #sub-title>View and upload any contracts related to this account.</template>
      <template #actions>
        <button v-if="$permissions.write('account', selectedAccount)" data-cy="add-new-btn" class="btn btn-primary" @click.prevent="onClickCreate">
          <i class="fas fa-fw fa-plus"></i> NEW
        </button>
      </template>
    </TabTitle>
    <div v-if="Object.keys(validationErrors).length > 0" class="alert alert-danger" role="alert">
      <h3 class="alert-heading font-size-h4 my-2">Error</h3>
      <p v-for="(error, index) in Object.values(validationErrors)" :key="index" class="mb-0">{{ error }}</p>
    </div>
    <div v-if="showAdd" data-cy="contracts-add" class="block">
      <div class="block-content bg-body-light">
        <div class="d-flex justify-content-between">
          <h4>Create/Edit contract</h4>
          <button data-cy="contract-close" class="btn btn-link text-danger fa-lg" @click.prevent="handleDropdownClose">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <p>
          Please fill out the form below with details of this contract. These details will be used when validating invoices, so please ensure they are
          100% correct.
        </p>
        <div>
          <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i>Contract Terms</h2>
          <div class="row push">
            <div class="col-lg-4">
              <p class="text-muted">Enter the main terms of the contract</p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <div class="row">
                <FormGroup
                  id="startDate"
                  v-model="form.startDate"
                  class="col-md-6"
                  type="datePicker"
                  label="Start Date"
                  placeholder=""
                  :error="validationErrors.startDate"
                />
                <FormGroup
                  id="endDate"
                  v-model="form.endDate"
                  class="col-md-6"
                  type="datePicker"
                  label="End Date"
                  placeholder=""
                  :error="validationErrors.endDate"
                />
              </div>
              <FormGroup id="terminationDate" v-model="form.terminationDate" type="datePicker" label="Termination Date" placeholder="" />
              <FormGroup id="paymentTerms" v-model="form.paymentTerms" type="number" label="Payment Terms (days)" placeholder="" />
              <FormGroup
                id="status"
                v-model="form.status"
                type="select"
                label="Status"
                :options="[
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' }
                ]"
              />
            </div>
          </div>
        </div>
        <div>
          <h2 class="content-heading pt-0"><i class="fa fa-fw fa-file-dollar text-muted mr-1"></i>Tariff Details</h2>
          <div class="row push">
            <div class="col-lg-4">
              <p class="text-muted">Details relating the the tariff</p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <FormGroup id="tariffName" v-model="form.tariffName" type="text" label="Tariff Name" placeholder="" />
              <FormGroup id="contractConsumption" v-model="form.contractConsumption" type="number" label="Contract Consumption" placeholder="" />
              <FormGroup
                id="volumeTolerance"
                v-model="form.volumeTolerance"
                type="slider"
                :min="-100"
                :max="100"
                slider-type="double"
                label="Annual Volume Tolerance"
                placeholder=""
              />
              <FormGroup id="renewablePercentage" v-model="form.renewablePercentage" type="slider" label="Renewable Percentage" placeholder="" />
              <FormGroup id="commissionRates" v-model="form.commissionRates" type="number" label="Commission Rates" placeholder="" />
              <FormGroup
                id="supplierId"
                v-model="form.supplierId"
                :options="sortedSuppliers.map(s => ({ label: s.name, value: s._id }))"
                label="Supplier Name"
                type="select2"
                :error="validationErrors.supplierId"
              />
              <FormGroup
                id="chargeableCclPercentage"
                v-model="form.chargeableCclPercentage"
                type="number"
                label="Chargeable CCL Percentage"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div>
          <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i>Contract File</h2>
          <div class="row push">
            <div class="col-lg-4">
              <p class="text-muted">Upload a PDF of your contract</p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <div class="form-group">
                <label class="d-block" for="contract-file"><i class="fa fa-file-contract fa-fw"></i> Contract File</label>
                <input id="contract-file" type="file" name="contract-file" accept=".pdf" enctype="multipart/form-data" @change="onFileSelect" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i>Contract Rates</h2>
          <div class="row push">
            <div class="col-lg-4">
              <p class="text-muted">Add any rates found on the contract using the form.</p>
              <p class="text-muted"><strong>Name</strong><br />Enter the rate name as shown on the contract.</p>
              <p class="text-muted">
                <strong>Value</strong><br />The unit rate that is shown on the contract. This will be used for validation on invoices.
              </p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <div v-for="(rate, index) in form.rates" :key="index" class="row align-items-center">
                <div class="col-md-5">
                  <FormGroup id="newContractType" v-model="rate.type" label="Name" placeholder="Enter a type" />
                </div>
                <div class="col-md-5">
                  <FormGroup id="newContractValue" v-model="rate.value" label="Value" placeholder="Enter a value" />
                </div>
                <div class="col-md-2">
                  <button class="btn btn-alt-danger" @click.prevent="onClickRemoveRate(index)">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-md-5">
                  <FormGroup id="newContractType" v-model="newRate.type" label="Name" placeholder="Enter a type" />
                </div>
                <div class="col-md-5">
                  <FormGroup id="newContractValue" v-model="newRate.value" label="Value" placeholder="Enter a value" />
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" @click.prevent="onClickAddRate">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i>Invoice Rate Mappings</h2>
          <div class="row push">
            <div class="col-lg-4">
              <p class="text-muted">
                When Captur processes an invoice, if it sees the <strong>On Invoice</strong> value on an invoice, it will automatically map this to
                the <strong>On Contract</strong> rate above.
              </p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th style="width: 35%">On Invoice</th>
                    <th style="width: 15%"></th>
                    <th style="width: 35%">On Contract</th>
                    <th style="width: 15%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(contractRateName, invoiceRateName) in form.rateTypeMapping" :key="invoiceRateName">
                    <td>
                      {{ invoiceRateName }}
                    </td>
                    <td>
                      <i class="fa fa-fw fa-arrow-right"></i>
                    </td>
                    <td>
                      {{ contractRateName }}
                    </td>
                    <td>
                      <button class="btn btn-alt-danger" @click.prevent="onClickRemoveRateMapping(invoiceRateName)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormGroup id="newRateMappingContract" v-model="newRateMapping.invoice" label="" placeholder="" />
                    </td>
                    <td>
                      <i class="fa fa-fw fa-arrow-right"></i>
                    </td>
                    <td>
                      <FormGroup
                        id="newRateMappingInvoice"
                        v-model="newRateMapping.contract"
                        label=""
                        type="select"
                        :options="form.rates.map(rate => ({ label: rate.type, value: rate.type }))"
                        placeholder=""
                      />
                    </td>
                    <td>
                      <button class="btn btn-primary" @click.prevent="onClickAddRateMapping">
                        <i class="fa fa-plus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block" @click="onClickSubmit">Submit</button>
        </div>
      </div>
    </div>
    <div v-if="loadingAction.get" class="d-flex justify-content-center mb-5">
      <div class="text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading contracts...</span>
        </div>
        <div class="font-w600">Loading contracts...</div>
      </div>
    </div>
    <div v-else-if="selectedAccount.contracts.length > 0" id="accordion" role="tablist">
      <div v-for="contract in selectedAccount.contracts" :key="contract._id" class="block block-rounded mb-4">
        <div :id="`accordion_h${contract._id}`" class="block-header block-header-default" role="tab">
          <a class="font-w600" data-toggle="collapse" data-parent="#accordion" :href="`#accordion_q${contract._id}`">
            {{ contract.startDate | date }} - {{ contract.endDate | date }}
            <span v-if="contract.status === 'active'" class="badge badge-success ml-1">Active</span>
          </a>
          <span>
            <!-- Active Button -->
            <button
              v-if="contract.status !== 'active'"
              class="btn btn-link font-w600 text-success"
              :disabled="loadingAction.updateContract"
              @click.prevent="() => onClickActive(contract)"
            >
              <i class="fa fa-fw fa-check-circle"></i> Set Active
            </button>

            <!-- Inactive Button -->
            <button
              v-if="contract.status === 'active'"
              class="btn btn-link font-w600 text-muted"
              :disabled="loadingAction.updateContract"
              @click.prevent="() => onClickActive(contract)"
            >
              <i class="fa fa-fw fa-times-circle"></i> Set Inactive
            </button>

            <!-- PDF Button -->
            <button
              v-if="contract.s3Key"
              class="btn btn-link font-w600"
              :disabled="loadingAction.downloadContract"
              @click.prevent="() => onClickDownload(contract)"
            >
              <i class="fa fa-fw fa-file-pdf"></i> {{ loadingAction.downloadContract ? 'Downloading...' : 'PDF' }}
            </button>
            <button v-else title="This contract has no PDF" class="btn btn-link font-w600" disabled><i class="fa fa-fw fa-file-pdf"></i> PDF</button>

            <!-- Edit Button -->
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-info"
              @click.prevent="() => onClickEdit(contract)"
            >
              <i class="fa fa-fw fa-pencil"></i> Edit
            </button>

            <!-- Delete Button -->
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-danger"
              :disabled="loadingAction.deleteContract"
              @click.prevent="() => onClickDelete(contract)"
            >
              <i class="fa fa-fw fa-trash"></i> {{ loadingAction.deleteContract === contract._id ? 'Deleting...' : 'Delete' }}
            </button>
          </span>
        </div>
        <div :id="`accordion_q${contract._id}`" class="collapse py-3" role="tabpanel" aria-labelledby="accordion_h2" data-parent="#accordion">
          <div class="row">
            <div class="col-xl-6">
              <SectionTitle>Contract Details</SectionTitle>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Tariff Name">{{ contract.tariffName }}</PropertyListItem>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Termination Date">{{
                contract.terminationDate | date
              }}</PropertyListItem>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Annual Contract Consumption">{{
                contract.contractConsumption
              }}</PropertyListItem>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Volume Tolerance">{{ contract.volumeTolerance }}</PropertyListItem>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Renewable %">{{ contract.renewablePercentage }}</PropertyListItem>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Commission Rates">{{ contract.commissionRates }}</PropertyListItem>
              <PropertyListItem class="d-flex align-items-center mb-2" title="Chargeable CCL %">{{
                contract.chargeableCclPercentage
              }}</PropertyListItem>
              <PropertyListItem v-if="contract.supplierId" class="d-flex align-items-center mb-2" title="Supplier">{{
                handleSupplierName(contract.supplierId)
              }}</PropertyListItem>
            </div>

            <div class="col-xl-6">
              <SectionTitle>Rates</SectionTitle>
              <PropertyListItem v-for="rate in contract.rates" :key="rate.type" class="d-flex align-items-center mb-2" :title="rate.type">{{
                rate.value
              }}</PropertyListItem>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-warning" role="alert">
      <p class="mb-0">No contracts uploaded.</p>
    </div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Message"
      text="Please confirm you would like to remove this contract."
      @close="
        () => {
          modals.remove = false;
        }
      "
      @submit="onDelete"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import contractForm from './contractForm';
import PropertyListItem from '@/components/base/PropertyListItem';
import SectionTitle from '@/components/base/SectionTitle';
import TabTitle from '@/components/base/TabTitle';

const defaultForm = {
  rates: [],
  startDate: null,
  endDate: null,
  terminationDate: '',
  paymentTerms: '',
  tariffName: '',
  contractConsumption: '',
  volumeTolerance: [-100, 100],
  renewablePercentage: '',
  commissionRates: '',
  chargeableCclPercentage: '',
  status: 'inactive',
  supplierId: null
};

export default {
  name: 'AssetAccountContracts',
  components: {
    ConfirmModal,
    FormGroup,
    PropertyListItem,
    SectionTitle,
    TabTitle
  },
  data() {
    return {
      showAdd: false,
      form: { ...defaultForm },
      file: null,
      mode: 'create',
      newRate: {
        value: '',
        type: ''
      },
      newRateMapping: {
        invoice: '',
        contract: ''
      },
      contractForm,
      modals: {
        remove: false
      }
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      selectedAccount: 'asset/selectedAccount',
      loadingAction: 'account/loadingAction',
      errorAction: 'account/errorAction',
      error: 'account/error',
      suppliers: 'supplier/suppliers',
      validationErrors: 'account/validationErrors'
    }),
    sortedSuppliers() {
      let suppliers = [...this.suppliers];

      suppliers.sort((a, b) => a.name.localeCompare(b.name));

      return suppliers;
    }
  },
  async mounted() {
    this.listSuppliers({ data: { params: { type: 'supplier', countryCode: this.asset.address.countryCode } } });
  },
  methods: {
    ...mapActions({
      getAccounts: 'asset/accounts',
      update: 'account/updateContract',
      toggleContractStatus: 'account/toggleContractStatus',
      create: 'account/addContract',
      delete: 'account/deleteContract',
      download: 'account/downloadContract',
      listSuppliers: 'supplier/list',
      uploadPdf: 'account/uploadContractPdf'
    }),
    ...mapMutations({
      setValidationErrors: 'account/SET_VALIDATION_ERRORS'
    }),
    handleDropdownClose() {
      this.showAdd = !this.showAdd;
      this.setValidationErrors({});
    },
    handleSupplierName(supplierId) {
      return this.sortedSuppliers.find(s => s.id === supplierId)?.name;
    },
    async onClickDownload(contract) {
      try {
        const accountType = this.selectedAccount.type.charAt(0).toUpperCase() + this.selectedAccount.type.slice(1);

        const formattedStartDate = moment.utc(contract.startDate).format('Do MMM YYYY');
        const formattedEndDate = moment.utc(contract.endDate).format('Do MMM YYYY');

        const fileName = `${this.asset.siteName}_${accountType}_${formattedStartDate}-${formattedEndDate}.pdf`.replace(/ /g, '_');

        const downloadUrl = await this.download({
          id: this.$route.params.accountId,
          contractId: contract._id,
          name: fileName
        });

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = downloadUrl;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download file', { position: 'bottom-center', duration: 3000 });
      }
    },
    async onClickDelete(contract) {
      this.modals.remove = contract;
    },
    async onDelete() {
      const remove = await this.delete({
        id: this.selectedAccount._id,
        contractId: this.modals.remove._id,
        data: { terminationDate: this.form.terminationDate ? true : false }
      });
      if (remove) {
        this.$toasted.success('Contract deleted succesfully.', { position: 'bottom-center', duration: 3000 });
        this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1' } } });
      } else {
        this.$toasted.error('Could not delete contract.', { position: 'bottom-center', duration: 3000 });
      }

      this.modals.remove = false;
    },
    onClickAddRate() {
      this.form.rates.push({ ...this.newRate });
      this.newRate.type = '';
      this.newRate.value = '';
    },
    onClickRemoveRate(index) {
      this.form.rates = this.form.rates.filter((rate, i) => i !== index);
    },
    onClickAddRateMapping() {
      this.form.rateTypeMapping = {
        ...this.form.rateTypeMapping,
        [this.newRateMapping.invoice]: this.newRateMapping.contract
      };

      this.newRateMapping.contract = '';
      this.newRateMapping.invoice = '';
    },
    onClickRemoveRateMapping(invoiceRate) {
      this.$delete(this.form.rateTypeMapping, invoiceRate);
    },
    async onClickActive(contract) {
      await this.toggleContractStatus({
        id: this.$route.params.accountId,
        contractId: contract._id
      });
      this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1' } } });
    },
    onClickCreate() {
      this.showAdd = true;
      this.mode = 'create';
      this.form = { ...defaultForm };
    },
    onClickEdit(contract) {
      this.showAdd = true;
      this.mode = 'edit';
      this.form = {
        ...contract
      };
    },
    async onClickSubmit() {
      if (this.newRate.type && this.newRate.value) {
        this.onClickAddRate();
      }

      const submissionForm = {
        ...this.form
      };

      if (this.file) {
        const pdfUpload = await this.uploadPdf({ data: this.file, id: this.$route.params.accountId });
        submissionForm.s3Key = pdfUpload.s3Key;
        this.file = null;
      }

      if (this.mode === 'create') {
        await this.create({ id: this.$route.params.accountId, data: submissionForm });
      } else {
        await this.update({
          id: this.$route.params.accountId,
          contractId: submissionForm._id,
          data: { ...submissionForm, _id: undefined }
        });
      }

      if (this.error?.message) {
        return this.$toasted.error(this.error.message || 'Could not create/edit contract', { position: 'bottom-center', duration: 3000 });
      }

      if (Object.keys(this.validationErrors).length === 0) {
        this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1' } } });

        this.form = { ...defaultForm };
        this.showAdd = false;
        this.setValidationErrors({});
      }

      window.scrollTo(0, 0);
    },
    onFileSelect(e) {
      const { name, files } = e.target;

      const formData = new FormData();

      formData.append(name, files[0], files[0].name);

      this.file = formData;
    }
  }
};
</script>
